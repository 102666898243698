// Generated by Framer (722666c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, getPropertyControls, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as sharedStyle from "../css/rerWrmQ65";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const serializationHash = "framer-ZFz6y"

const variantClassNames = {qDwaIyUEs: "framer-v-zo65t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({content, height, iconName, id, width, ...props}) => { return {...props, GJdJmFCj5: content ?? props.GJdJmFCj5 ?? "GraduationCap", JHxom1g1e: iconName ?? props.JHxom1g1e ?? "Smiley"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;content?: string;iconName?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GJdJmFCj5, JHxom1g1e, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "qDwaIyUEs", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-zo65t", className, classNames)} data-border data-framer-name={"primary"} layoutDependency={layoutDependency} layoutId={"qDwaIyUEs"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-5970a3c6-0f13-482e-8fb6-b8cb7d627b04, rgba(216, 231, 242, 0.07))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-eb09dbbf-ef85-4b7f-81a5-44e9b062efb7, rgb(4, 7, 13))", borderBottomLeftRadius: 60, borderBottomRightRadius: 60, borderTopLeftRadius: 60, borderTopRightRadius: 60, ...style}}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-pax65-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"Lmk5aNVlD-container"} nodeId={"Lmk5aNVlD"} rendersWithMotion scopeId={"rOlsEceWf"}><Phosphor color={"var(--token-f195ea74-7512-4096-8d91-0e7c7e10d0ab, rgb(213, 219, 230))"} height={"100%"} iconSearch={"House"} iconSelection={JHxom1g1e} id={"Lmk5aNVlD"} layoutId={"Lmk5aNVlD"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1u349i7"} data-styles-preset={"rerWrmQ65"}>GraduationCap</motion.p></React.Fragment>} className={"framer-1x6bqto"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"xSOJ_ShuV"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={GJdJmFCj5} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZFz6y.framer-1dxqqrl, .framer-ZFz6y .framer-1dxqqrl { display: block; }", ".framer-ZFz6y.framer-zo65t { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 6px 12px 6px 12px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-ZFz6y .framer-pax65-container { flex: none; height: 17px; position: relative; width: 17px; }", ".framer-ZFz6y .framer-1x6bqto { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZFz6y.framer-zo65t { gap: 0px; } .framer-ZFz6y.framer-zo65t > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-ZFz6y.framer-zo65t > :first-child { margin-left: 0px; } .framer-ZFz6y.framer-zo65t > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ".framer-ZFz6y[data-border=\"true\"]::after, .framer-ZFz6y [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 134
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"GJdJmFCj5":"content","JHxom1g1e":"iconName"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrOlsEceWf: React.ComponentType<Props> = withCSS(Component, css, "framer-ZFz6y") as typeof Component;
export default FramerrOlsEceWf;

FramerrOlsEceWf.displayName = "Elements / Badge";

FramerrOlsEceWf.defaultProps = {height: 29, width: 134};

addPropertyControls(FramerrOlsEceWf, {GJdJmFCj5: {defaultValue: "GraduationCap", displayTextArea: false, title: "Content", type: ControlType.String}, JHxom1g1e: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Smiley", description: undefined, hidden: undefined, title: "icon name"}} as any)

addFonts(FramerrOlsEceWf, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})